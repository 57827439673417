import React, { FC, useMemo } from 'react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { classes, st } from './Root.st.css';
import { DataHook } from '../../../../constants/DataHook';
import { Header } from '../Header';
import { Divider } from '../common/Divider';
import {
  AccountSettings,
  shouldShowBlockedMembersItem,
  shouldShowCommunityUrlItem,
  shouldShowProfileVisibilityItem,
} from '../Sections/AccountSettings';
import { AccountForm } from '../AccountForm';
import { PublicInfo } from '../Sections/PublicInfo';
import { Account } from '../Sections/Account';
import { SocialLinks } from '../Sections/SocialLinks';
import settingsParams from '../../settingsParams';
import {
  Experiment,
  Field,
  FieldSection,
  PageAlignment,
} from '../../../../types';
import { CTAGroup } from '../common/CTAGroup';
import {
  useFields,
  useMember,
  useSiteContext,
} from '../../../../contexts/widget';
import { Toast } from '../Toast';
import { AddressSection } from '../Sections/Address';

const useFieldPresence = (fields: Field[]) => {
  const hasDisplayInfoFields = fields.some(
    (field) => field.section === FieldSection.DISPLAY_INFO,
  );
  const hasAccountFields = fields.some(
    (field) => field.section === FieldSection.GENERAL,
  );
  const hasAddressFields = fields.some(
    (field) => field.section === FieldSection.ADDRESS,
  );
  const hasSocialFields = fields.some(
    (field) => field.section === FieldSection.SOCIAL,
  );

  return {
    hasDisplayInfoFields,
    hasAccountFields,
    hasAddressFields,
    hasSocialFields,
    hasAnyFields: hasAccountFields || hasAddressFields || hasSocialFields,
  };
};

const useAccountSettingsVisibility = () => {
  const { experiments } = useExperiments();
  const { role, privacyStatus } = useMember();
  const { showPublicSections, isSocial, isProfilePageInstalled } =
    useSiteContext();

  const isGeneralSettingsEnabled = experiments.enabled(
    Experiment.EnableGeneralSettings,
  );

  const shouldShowAccountSettings = useMemo(() => {
    if (isGeneralSettingsEnabled) {
      const hasContent =
        shouldShowBlockedMembersItem(role) ||
        shouldShowCommunityUrlItem(isProfilePageInstalled, privacyStatus) ||
        shouldShowProfileVisibilityItem(role);
      return showPublicSections && hasContent;
    }

    return isSocial;
  }, [
    role,
    privacyStatus,
    showPublicSections,
    isGeneralSettingsEnabled,
    isSocial,
    isProfilePageInstalled,
  ]);

  return { shouldShowAccountSettings };
};

export const Root: FC = () => {
  const { isMobile, isRTL } = useEnvironment();
  const { experiments } = useExperiments();
  const settings = useSettings();
  const fields = useFields();
  const {
    hasAnyFields,
    hasDisplayInfoFields,
    hasAccountFields,
    hasAddressFields,
    hasSocialFields,
  } = useFieldPresence(fields);
  const { isSocial, showPublicSections } = useSiteContext();
  const { shouldShowAccountSettings } = useAccountSettingsVisibility();
  const isGeneralSettingsEnabled = experiments.enabled(
    Experiment.EnableGeneralSettings,
  );

  const pageAlignment = settings.get(
    settingsParams.pageAlignment,
  ) as PageAlignment;
  const isPageLayoutRtl =
    pageAlignment === PageAlignment.right ? !isRTL : isRTL;
  const shouldShowPublicInfo = isGeneralSettingsEnabled
    ? showPublicSections
    : isSocial;
  const shouldShowCTAGroup = hasAnyFields || (isMobile && hasDisplayInfoFields);

  return (
    <>
      <Toast />
      <div
        className={st(classes.root, { alignRight: isPageLayoutRtl })}
        data-hook={DataHook.WidgetWrapper}
        dir={isPageLayoutRtl ? 'rtl' : 'ltr'}
      >
        <AccountForm>
          <Header />
          {isMobile ? null : <Divider />}
          <div
            className={st(classes.sections, {
              alignCenter: pageAlignment === PageAlignment.center,
            })}
          >
            {shouldShowPublicInfo && <PublicInfo />}
            {shouldShowPublicInfo && hasAnyFields && <Divider />}
            {hasAccountFields && <Account />}
            {hasAddressFields && <AddressSection />}
            {hasSocialFields && <SocialLinks />}
            {shouldShowCTAGroup && (
              <div className={classes.ctaGroup}>
                <CTAGroup />
              </div>
            )}
            {shouldShowAccountSettings && <Divider />}
          </div>
        </AccountForm>
        {shouldShowAccountSettings && <AccountSettings />}
      </div>
    </>
  );
};
